import React, { Fragment } from "react";

function Alarmes() {
  return (
    <Fragment>
      <section
        class="uk-section"
        style={{ paddingTop: "0px", paddingBottom: "0px" }}
      >
        <div
          class="uk-position-relative uk-visible-toggle uk-dark"
          tabindex="-1"
          uk-slideshow="min-height: 350; max-height: 350"
        >
          <ul class="uk-slideshow-items">
            <li>
              <img
                src={require("../../assets/img/img-2.jpg").default}
                uk-cover
              />
              <div class="uk-overlay-primary uk-position-cover"></div>
              <div class="uk-overlay uk-position-center uk-text-center uk-light padd">
                <h1 class="uk-margin-remove uk-h1 uk-text-light uk-text-uppercase">
                  Alarme avec télésurveillance pour professionnel
                </h1>
                <br />

                <p>
                  Vous recherchez un système d'alarme performant pour protéger
                  vos bureaux ou votre commerce ?
                </p>
                <br />
                <p>
                  Alarme anti-intrusion connectée + Télésurveillance 24h/24,
                  <br />
                  Nous protégeons votre foyer, vous profitez du reste.
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section class="uk-section uk-section-large ">
        <div class="uk-container">
          <p class="uk-h5 uk-padding-bottom-large uk-text-center">
            Risque de cambriolage et d’incendie ? Avec ASTOR PROTECT, vous êtes
            entre de bonnes mains.
            <br />
            ASTOR PROTECT vous propose le meilleur service de télésurveillance :
            des services performants effectués par des professionnels.
          </p>
          <br />
          <br />
          <br />
          <div class="uk-child-width-1-3@m" data-uk-grid>
            <div>
              <img
                class="uk-align-center uk-margin-remove-bottom"
                src={require("../../assets/img/icon-2.png").default}
                width="50"
              />
              <br />
              <p class="uk-text-center">
                Un abonnement tout compris système d'alarme connecté,
                maintenance et télésurveillance 24h/24.
              </p>
            </div>
            <div>
              <img
                class="uk-align-center uk-margin-remove-bottom"
                src={require("../../assets/img/icon-2.png").default}
                width="50"
              />
              <br />
              <p class="uk-text-center">
                La rapidité d’action
                <br />
                En cas d’alarme, un appel de contrôle est émis en moins de 30
                secondes (1).
              </p>
            </div>

            <div>
              <img
                class="uk-align-center uk-margin-remove-bottom"
                src={require("../../assets/img/icon-2.png").default}
                width="50"
              />
              <br />
              <p class="uk-text-center">
                Des professionnels de la sécurité <br />
                Intervention d’un agent de sécurité agréé et alerte des forces
                de l’ordre si nécessaire.
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <p class="uk-h5 uk-padding-bottom-large uk-text-center is-primary">
            Protéger votre foyer avec un système d'alarme connecté mis à
            disposition et installé par nos soins.
          </p>
          <br />
          <br />
          <p>
            Avec ASTOR PROTECT, vous bénéficiez d'un équipement de haute
            technologie pensé et conçu pour assurer efficacement votre sécurité
            et celle de vos biens. Mais parce qu'être protégé ne veut pas dire
            bousculer son quotidien, nous vous proposons un matériel d’alarme
            efficace et discret. Sa mise à disposition, son installation et sa
            maintenance, y compris le changement des piles, sont incluses dans
            votre abonnement.
          </p>
          <br />
          <div class="uk-child-width-1-2@m" data-uk-grid>
            <div>
              <h3 class="uk-h1 uk-text-bolder uk-text-capitalize is-primary">
                Vous avez oublié d'activer votre alarme ?
              </h3>
              <p>
                Avec notre application, vous pilotez l'activation de votre
                système d'alarme avec votre smartphone de n’importe où et
                consultez les photos prises par les détecteurs en cas d'alarme.
                C'est aussi simple que ça.
              </p>
            </div>

            <div>
              <img
                width="650"
                height="533"
                src={require("../../assets/img/img-2.jpg").default}
              />
            </div>

            <div>
              <img
                src={require("../../assets/img/img-3.jpg").default}
                width="650"
                height="533"
              />
            </div>
            <div>
              <h3 class="uk-h1 uk-text-bolder uk-text-capitalize is-primary">
                Alarme avec télésurveillance pour professionnel
              </h3>
              <p>
                Vous recherchez un système d'alarme performant pour protéger vos
                bureaux ou votre commerce ? ASTOR PROTECT accompagne les
                professionnels dans la protection de leurs locaux : touche SOS,
                bouton panique, lecteur de badges d'accès pour suivre les
                entrées et sorties même à distance... Les systèmes d’alarme
                professionnels ASTOR PROTEC s’appuient sur des technologies de
                pointe adaptées aux besoins des entreprises. Ils sont connectés
                en permanence au centre de télésurveillance ASTOR PROTECT. Nos
                agents veillent, prêts à intervenir à la moindre alerte :
                incendie, intrusion, agression ou hold-up.
              </p>
            </div>
          </div>
          <br />
          <br />
          <p class="uk-h5 uk-padding-bottom-large uk-text-center">
            Pour protéger votre foyer, un système d'alarme connecté est mis à
            disposition et installé par nos soins.
          </p>
          <br />
          <div class="uk-child-width-1-2@m" data-uk-grid>
            <div>
              <p>
                ASTOR PROTECT a pensé et conçu un équipement de haute
                technologie pour assurer efficacement votre sécurité et celle de
                vos biens. Mais parce qu'être protégé ne veut pas dire bousculer
                son quotidien, nous vous proposons un matériel de
                télésurveillance efficace et discret. Sa mise à disposition, son
                installation et sa maintenance, y compris le changement des
                piles, sont incluses dans votre abonnement.
              </p>
            </div>

            <div>
              <img
                width="650"
                height="533"
                src={require("../../assets/img/img-2.jpg").default}
              />
            </div>

            <div>
              <img
                src={require("../../assets/img/img-3.jpg").default}
                width="650"
                height="533"
              />
            </div>
            <div>
              <p>
                En cas d'intrusion, nous agissons immédiatement :
                <br />
                • Le système d’alarme détecte le potentiel intrus, la sirène se
                déclenche et l’alarme est transmise au centre de surveillance
                ASTOR PROTECT ;
                <br />
                • En moins de 30 secondes, nous procédons à un appel de
                contrôle ;
                <br />• Si nécessaire, et selon la formule, nous dépêchons un
                agent de sécurité agréé à votre domicile et alertons les forces
                de l’ordre.
              </p>
            </div>
          </div>
          <br />
          <br />
          <p class="uk-h3 uk-padding-bottom-large uk-text-center is-primary">
            Pas de mauvaises surprises, tout est compris, même le changement des
            piles !
          </p>
          <br />
          <br />
          <div class="uk-child-width-1-4@m" data-uk-grid>
            <div>
              <img
                class="uk-align-center uk-margin-remove-bottom"
                src={require("../../assets/img/icon-2.png").default}
                width="50"
              />
              <br />
              <p class="uk-text-center">
                Pas d'achat de matériel : mise à disposition pendant toute la
                durée de l'abonnement
              </p>
            </div>
            <div>
              <img
                class="uk-align-center uk-margin-remove-bottom"
                src={require("../../assets/img/icon-2.png").default}
                width="50"
              />
              <br />
              <p class="uk-text-center">
                Installation, contrôle à distance et maintenance du matériel par
                nos techniciens-conseillers
              </p>
            </div>
            <div>
              <img
                class="uk-align-center uk-margin-remove-bottom"
                src={require("../../assets/img/icon-2.png").default}
                width="50"
              />
              <br />
              <p class="uk-text-center">
                Intervention d'un agent de sécurité en cas d'alarme, la selon
                formule
              </p>
            </div>

            <div>
              <img
                class="uk-align-center uk-margin-remove-bottom"
                src={require("../../assets/img/icon-2.png").default}
                width="50"
              />
              <br />
              <p class="uk-text-center">
                Alerte des forces de l'ordre si nécessaire(2)
              </p>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div class="uk-child-width-1-2@m" data-uk-grid>
            <div>
              <p>
                Traitement des alertes entre 30 et 80 secondes par le centre de
                télésurveillance Réactivité et analyse des enregistrements de
                votre alarme anti-intrusion Vous pouvez fermer votre
                établissement en toute sérénité ! Tout événement inhabituel
                génère une alerte au centre de télésurveillance 24h/24 et 7J/7
                pour identifier la cause du déclenchement : tentative
                d’effraction par un cambrioleur, déclenchement du détecteur de
                fumée ou de CO2. Grâce à l’analyse d’alerte, le centre de
                télésurveillance peut effectuer une levée de doute. Si
                l’intrusion est confirmée, l’opérateur contacte les forces de
                l’ordre sur un numéro prioritaire. En cas de déclenchement
                d’alarme, vous pouvez recevoir également les alertes transmises
                au centre de télésurveillance.
              </p>
            </div>

            <div>
              <img
                width="650"
                height="533"
                src={require("../../assets/img/img-2.jpg").default}
              />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export { Alarmes };
