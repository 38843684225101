import React, { Component } from "react";

import { Router as Routers, Route, Switch, HashRouter } from "react-router-dom";

import Header from "../components/header.js";
import Footer from "../components/footer.js";

import history from "../utils/history";
import { Home } from "../modules/home/index.js";
import { About } from "../modules/about/index.js";
import { Alarmes } from "../modules/alarmes/index.js";
import { Video } from "../modules/videoprotection/index.js";

const Router = (props) => {
  let routes = (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/about" component={About} />
      <Route exact path="/alarmes" component={Alarmes} />
      <Route exact path="/video" component={Video} />
    </Switch>
  );

  return (
    <Routers history={history}>
      <Header {...props} />
      {routes}

      <Footer />
    </Routers>
  );
};
export default Router;
