import React, { Fragment } from "react";

function About() {
  return (
    <Fragment>
      <section
        class="uk-section"
        style={{ paddingTop: "0px", paddingBottom: "0px" }}
      >
        <div
          class="uk-position-relative uk-visible-toggle uk-dark"
          tabindex="-1"
          uk-slideshow="min-height: 350; max-height: 350"
        >
          <ul class="uk-slideshow-items">
            <li>
              <img
                src={require("../../assets/img/img-2.jpg").default}
                uk-cover
              />
              <div class="uk-overlay-primary uk-position-cover"></div>
              <div class="uk-overlay uk-position-center uk-text-center uk-light padd">
                <h1 class="uk-margin-remove uk-h1 uk-text-light uk-text-uppercase">
                  À propos de
                  <br />
                  nous
                </h1>
                <br />

                <br />
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section class="uk-section uk-section-large ">
        <div class="uk-container">
          <div class="uk-child-width-1-1@m uk-flex uk-flex-center" data-uk-grid>
            <article class="uk-article">
              <h1 class="uk-article-title">
                <a class="is-primary" href="">
                  Qui somme-nous?
                </a>
              </h1>

              <p>
                ASTOR PROTECT Sarl est une start-up qui développe des
                technologies innovantes à travers sa plateforme de Vidéo
                Management Security & Artificial Intelligence. À partir de cette
                plateforme, elle fournit une gamme complète de solutions
                (produits/systèmes) de sûreté et de sécurité proposant des
                services de télésurveillance, grâce à sa technologie de
                reconnaissance des objets et de reconnaissance faciale, basée
                sur de l’intelligence artificielle.
                <br />
                <br />
                ASTOR PROTECT a été fondée par un entrepreneur passionné des
                nouvelles technologies, qui a réuni à son sein des ingénieurs et
                des managers pour donner corps à cette start-up. Nous sommes
                spécialisés dans les solutions créatives et innovantes pour
                optimiser les processus de surveillance et de sécurité. Nous
                comptons parmi ces solutions des produits de sécurité domestique
                tels que des avertisseurs de fumée, des alarmes anti-intrusion,
                des systèmes de surveillance domestiques et professionnels, etc.
                <br />
                <br />
                Par ailleurs, Astor Protect se positionne comme l’un des
                pionniers des services intégrant la reconnaissance faciale, la
                reconnaissance des objets, les caméras de comptage de personnes
                (qui intègrent également des algorithmes de Deep Learning
                pouvant être continuellement formés), les caméras de pointage de
                présence, ainsi que le développement de la toute première
                plateforme de vidéosurveillance en Afrique « V.M.A.I.S » (Vidéo
                Management Security & Artificial Intelligence).
              </p>
            </article>
          </div>

          <br />

          <br />
          <br />
          <div class="uk-child-width-1-2@m" data-uk-grid>
            <div>
              <h3 class="uk-h1 uk-text-bolder uk-text-capitalize is-primary">
                Bien Ensemble
              </h3>
              <p>
                Bien plus qu’une signature, il s’agit d’une réelle philosophie
                qui nous anime au quotidien. C’est également la promesse de
                toujours mieux accompagner nos collaborateurs et nos clients.
              </p>
            </div>

            <div>
              <img
                width="650"
                height="533"
                src={require("../../assets/img/img-2.jpg").default}
              />
            </div>

            <div>
              <img
                src={require("../../assets/img/img-3.jpg").default}
                width="650"
                height="533"
              />
            </div>
            <div>
              <h3 class="uk-h1 uk-text-bolder uk-text-capitalize is-primary">
                Nos valeurs ?
              </h3>
              <p>
                Pro, simple & sympa ! Elles incarnent notre volonté d’être aux
                côtés de nos collaborateurs et de nos clients, de leurs attentes
                et besoins
              </p>
            </div>

            <div>
              <h3 class="uk-h1 uk-text-bolder uk-text-capitalize is-primary">
                Notre idéal
              </h3>
              <p>
                Aujourd’hui, dans un monde qui change, veut et doit changer,
                nous croyons qu’ensemble nous pouvons nous inventer UN MONDE +
                SOFT. Nous nous attachons à faire des technologies le meilleur
                de ce que l’on peut en faire :
                <br /> • Créer de nouveaux liens entre les Hommes ;
                <br />
                • Permettre à tout le monde de prendre soin de son entourage ;
                <br />• Permettre à tous d’améliorer son quotidien, de révéler
                ses talents, de vivre pleinement et sereinement ses envies, ses
                passions, dans tous les univers de la vie.{" "}
              </p>
            </div>
            <div>
              <img
                src={require("../../assets/img/img-3.jpg").default}
                width="650"
                height="533"
              />
            </div>
            <div>
              <img
                src={require("../../assets/img/img-3.jpg").default}
                width="650"
                height="533"
              />
            </div>
            <div>
              <h3 class="uk-h1 uk-text-bolder uk-text-capitalize is-primary">
                Notre ambition
              </h3>
              <p>
                <b class="uk-text-bold">
                  Devenir le leader culturel de l’amélioration de la vie
                  sécuritaire et de la serruté connectée, pour les habitants du
                  monde et de leurs foyers.
                </b>
                <br />
                <br />
                Parce qu’elle ouvre et montre le chemin, de devenir leader
                sécuritaire et de serruté, notre ambition est d’accompagner les
                clients dans le monde connecté d’aujourd’hui et de demain.
                Fidèle à notre culture, nous nous attelons à anticiper les
                nouveaux modes de vie et développons une culture d’anticipation
                au service de nos clients et de leurs petits mondes.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export { About };
