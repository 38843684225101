import React, { Fragment } from "react";

function Video() {
  return (
    <Fragment>
      <section
        class="uk-section"
        style={{ paddingTop: "0px", paddingBottom: "0px" }}
      >
        <div
          class="uk-position-relative uk-visible-toggle uk-dark"
          tabindex="-1"
          uk-slideshow="min-height: 350; max-height: 350"
        >
          <ul class="uk-slideshow-items">
            <li>
              <img
                src={require("../../assets/img/img-2.jpg").default}
                uk-cover
              />
              <div class="uk-overlay-primary uk-position-cover"></div>
              <div class="uk-overlay uk-position-center uk-text-center uk-light padd">
                <h1 class="uk-margin-remove uk-h1 uk-text-light uk-text-uppercase">
                  VIDEO PROTECTION
                </h1>
                <br />

                <p>
                  La télé-vidéosurveillance à domicile capable de reconnaître
                  les humains est une solution complète de vidéosurveillance et
                  de télé-vidéo-surveillance pour la maison.
                </p>
                <br />
              </div>
            </li>
          </ul>
        </div>
      </section>

      <section class="uk-section uk-section-large ">
        <div class="uk-container">
          <p class="uk-h5 uk-padding-bottom-large uk-text-center">
            ASTOR PROTECT conçoit des solutions de sécurité électronique sur
            mesure et réalise leur installation, dans le respect des règles. À
            travers notre plateforme de Vidéo Management Security & Artificial
            Intelligence, nos solutions gestion permettent le pilotage et la
            maîtrise complète de vos installations de sûreté.
            <br />
            Nous vous accompagnons également dans l’évolution des systèmes
            existants, en intégrant des équipements de sécurité novateurs et
            intelligents au service de la protection des personnes au sein de
            votre établissement.
          </p>
          <br />
          <br />
          <br />
          <h2 className="uk-h2 is-primary">
            DÉVELOPPEZ VOS SYSTÈMES DE SÉCURITÉ INTELLIGENTS
          </h2>
          <p>
            Liste noire / liste blanche via la technologie de reconnaissance
            faciale.
            <br />
            La reconnaissance faciale peut également être utilisée comme
            solution de liste noire / liste blanche, détectant un grand nombre
            de personnes passant par une zone spécifique en temps réel, et
            générant des événements en cas de correspondance des individus
            détectés dans la foule avec ceux de la liste noire / liste blanche
            enregistrés dans la base de données. Tous les journaux d'événements
            stockés permettent une analyse statistique et d'autres services.
            <br />
            L’architecture est créée de A à Z, du choix du matériel à la mise en
            service, en passant par l’installation et l’interface. Nous assurons
            également la formation du personnel pour l’exploitation de vos
            installations.
          </p>
          <h2 className="uk-h2 is-primary">COMMENT ÇA FONCTIONNE ?</h2>
          <p>
            • Nos experts vous conseillent en fonction de la typologie de votre
            site et de vos risques ;
            <br />
            • Nous choisissons le matériel pour qu’il s’intègre au mieux à votre
            organisation et aux ressources dédiées ;
            <br />
            • Nos techniciens réalisent l’installation, l’intégration et les
            tests afin de mettre en service une solution de sécurité
            électronique sur mesure.
            <br />• Vos salariés sont formés à l’utilisation des systèmes.
          </p>
        </div>
      </section>
    </Fragment>
  );
}

export { Video };
