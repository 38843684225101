import React, { Fragment } from "react";

function Home() {
  return (
    <Fragment>
      <section
        class="uk-section"
        style={{ paddingTop: " 0px", paddingBottom: "0px" }}
      >
        <div
          class="uk-position-relative uk-visible-toggle uk-dark"
          tabindex="-1"
          data-uk-slideshow="min-height: 350; max-height: 550"
        >
          <ul class="uk-slideshow-items">
            <li>
              <img
                src={require("../../assets/img/slide1-min.jpg").default}
                alt=""
                data-uk-cover
              />
              <div class="uk-overlay-primary uk-position-cover"></div>
              <div class="uk-overlay uk-position-center uk-text-center uk-light padd">
                <h1 class="uk-margin-remove uk-h1 uk-text-light uk-text-uppercase">
                  Lorem ipsum dolor sit amet adipiscing,
                  <br />
                  consectetur
                </h1>
                <br />

                <br />
                <button class="uk-button uk-button-danger uk-button-large uk-text-bold">
                  Contactez-nous
                </button>
              </div>
            </li>

            <li>
              <img src="img/img-4.jpg" alt="" data-uk-cover />
              <div class="uk-position-center uk-position-small uk-text-center uk-light">
                <h2 class="uk-margin-remove">Center</h2>
                <p class="uk-margin-remove">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
              </div>
            </li>
          </ul>

          <a
            class="uk-position-center-left uk-position-small uk-light"
            href="#"
            data-uk-slidenav-previous
            data-uk-slideshow-item="previous"
          ></a>
          <a
            class="uk-position-center-right uk-position-small uk-light"
            href="#"
            data-uk-slidenav-next
            data-uk-slideshow-item="next"
          ></a>
        </div>
      </section>

      <section class="uk-box-shadow-small">
        <div class="uk-container-expand">
          <div
            class="uk-grid-collapse uk-child-width-1-3@m uk-grid-match"
            data-uk-grid
          >
            <div class="background-section-1">
              <div class="uk-padding">
                <div
                  class="uk-grid-collapse uk-child-width-expand@m uk-grid-small"
                  data-uk-grid
                >
                  <div>
                    <img
                      src={require("../../assets/img/icon-1.png").default}
                      width="80"
                      alt=""
                    />
                  </div>
                  <div>
                    <h2 class="uk-margin-remove uk-text-uppercase">
                      Innovation
                    </h2>
                    <p class="uk-margin-remove">
                      Des solutions de protection à la carte en fonction de vos
                      besoins Des tarifs flexibles et fortement compétitifs.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="background-section-2">
              <div class="uk-padding">
                <div class="uk-child-width-expand@m uk-grid-small" data-uk-grid>
                  <div>
                    <img
                      src={require("../../assets/img/icon-3.png").default}
                      width="80"
                      alt=""
                    />
                  </div>
                  <div>
                    <h2 class="uk-margin-remove uk-text-uppercase">Fiable</h2>
                    <p class="uk-margin-remove">
                      Une équipe de professionnels de la surveillance et de la
                      d’énergie garantissant votre tranqui.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="background-section-3">
              <div class="uk-padding">
                <div class="uk-child-width-expand@m uk-grid-small" data-uk-grid>
                  <div>
                    <img
                      class="uk-align-center"
                      src={require("../../assets/img/icon-2.png").default}
                      width="80"
                      alt=""
                    />
                  </div>
                  <div>
                    <h2 class="uk-margin-remove uk-text-uppercase">Sérénite</h2>
                    <p class="uk-margin-remove">
                      Des équipements de haute technologie pour garantir un
                      niveau de sécurité optimal Un meilleur contrôle de.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="uk-section uk-section-large">
        <div class="uk-container">
          <h3 class="uk-h2 uk-text-bold">Services</h3>

          <br />
          <div class="uk-child-width-1-2@m" data-uk-grid>
            <div>
              <h3 class="uk-h4 uk-text-capitalize">Trafic</h3>
              <p>
                Notre solution de vidéo-verbalisation intelligent améliore
                l’agilité et supprime la complexité pour l'encaissement des
                amendes en Afrique. Notre solution vidéo-verbalisation est un
                dispositif qui permet de verbaliser à distance les auteurs
                d'infractions. Ce système repose sur les caméras de surveillance
                intelligentes implantées le long du réseau routier public, et
                qui captent en continu les mouvements des usagers de la route.
                Nous améliorons la sécurité sur les routes et recueillions des
                informations pour l'infrastructure routière (améliorations
                utilisant la technologie basée sur l'IA)
              </p>
              <p>
                <br />
                <button class="uk-button uk-button-danger uk-text-bold">
                  En savoir plus
                </button>
              </p>
            </div>

            <div>
              <img
                width="650"
                height="533"
                src={require("../../assets/img/img-2.jpg").default}
              />
            </div>

            <div>
              <img
                src={require("../../assets/img/img-3.jpg").default}
                width="650"
                height="533"
              />
            </div>
            <div>
              <h3 class="uk-h4">Pénitencier</h3>
              <p>
                Prisons et sites pénitentiaires Des solutions de
                vidéosurveillance intégrant les fonctionnalités de
                reconnaissance faciale et d’identification faciale pour plus de
                sécurité et d'efficacité
              </p>
              <p>
                <br />
                <button class="uk-button uk-button-danger uk-text-bold">
                  En savoir plus
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="uk-section-large uk-section-muted">
        <div class="uk-container">
          <div class="uk-child-width-1-2@m" data-uk-grid>
            <div>
              <h3 class="uk-h3 uk-text-bold">
                Protection des Travailleurs isolés
              </h3>
              <h3 class="uk-h4">
                LES RISQUES PROFESSIONNELS LIÉS À L’ISOLEMENT AU TRAVAIL
              </h3>
              <p class="uk-padding-remove">
                Le travail isolé augmente les contraintes de travail et accroît
                également le risque d’un incident ou d’un accident sur place.
                Bien sûr, il est essentiel d’identifier les situations de
                travail isolé. Il faut aussi pouvoir évaluer les risques
                auxquels les travailleurs sont exposés afin de trouver les
                solutions adéquates..
                <br />
                <button class="uk-button uk-button-danger uk-text-bold uk-margin-top">
                  En savoir plus
                </button>
              </p>

              <h3 class="uk-h3 uk-text-bold">
                Protection des Travailleurs isolés
              </h3>
              <h3 class="uk-h4">Tracking Flotte</h3>
              <p class="uk-padding-remove">
                Accédez à tout moment à l’activité de votre flotte, grâce à
                l’interface Tracking Flotte.
                <br />
                <button class="uk-button uk-button-danger uk-text-bold uk-margin-top">
                  En savoir plus
                </button>
              </p>
            </div>
            <div>
              <img
                src={require("../../assets/img/slide1.jpg").default}
                width="650"
                height="533"
              />
            </div>
          </div>
        </div>
      </section>
      <section class="uk-section-large">
        <div class="uk-container">
          <div class="uk-grid">
            <div class="uk-child-width-1-2@m" data-uk-grid>
              <div>
                <h3 class="uk-h4 uk-text-capitalize">
                  Notre solution de Vidéo-Protection
                </h3>
                <p>
                  Nos systèmes de vidéo protection se distinguent sur le marché
                  grâce aux intelligences artificielles extrêmement fiables.
                  Elles peuvent être personnalisées de manière illimitée tout en
                  offrant une sécurité durable en termes d’investissement. Cette
                  flexibilité de fonctionnalité permet une utilisation variée
                  dans différents secteurs d’activités (Les domiciles privés,
                  les commerces, les hôpitaux, les écoles et tous autres formes
                  d’institutions public et privée etc).
                </p>
                <hr />
                <p>
                  <br />
                  <button class="uk-button uk-button-danger uk-text-bold">
                    En savoir plus
                  </button>
                </p>

                <h3 class="uk-h4 uk-text-capitalize">
                  Protégez votre foyer avec un système d'alarme connecté mis à
                  disposition et installé par nos soins.
                </h3>
                <p>
                  Avec ASTOR PROTECT, vous bénéficiez d'un équipement de haute
                  technologie pensé et conçu pour assurer efficacement votre
                  sécurité et celle de vos biens. Mais parce qu'être protégé ne
                  veut pas dire bousculer son quotidien, nous vous proposons un
                  matériel d’alarme efficace et discret. Sa mise à disposition,
                  son installation et sa maintenance, y compris le changement
                  des piles, sont incluses dans votre abonnement..
                </p>
                <hr />
                <p>
                  <br />
                  <button class="uk-button uk-button-danger uk-text-bold">
                    En savoir plus
                  </button>
                </p>
              </div>

              <div>
                <img
                  src={require("../../assets/img/img-1.jpg").default}
                  width="650"
                  height="533"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="uk-section uk-section-small uk-section-muted">
        <div class="uk-container">
          <h3 class="uk-h2 uk-text-bold">Nous services</h3>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt
          </p>
          <br />
          <div class="uk-child-width-1-3@m uk-grid-match" data-uk-grid>
            <div>
              <div
                class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-1@s uk-margin uk-box-shadow-small uk-box-shadow-hover-large"
                uk-grid
              >
                <div>
                  <div class="uk-card-body">
                    <h3 class="uk-card-title is-primary">
                      EXPLOITEZ LE POTENTIEL CACHÉ
                    </h3>
                    <p>
                      En dépit des quantités incroyables de données vidéos
                      obtenues par les caméras, les statistiques obtenues
                      montrent que seulement 10% de ces données sont utilisées
                      et que la plupart des 90% restants perdent toute valeur
                      immédiatement après avoir été générés. Nous pensons que
                      l'avenir réside dans l'exploitation du potentiel caché des
                      données. En utilisant les 100% de données vidéo générée. .
                    </p>
                    <p>
                      <br />
                      <button class="uk-button uk-button-danger uk-text-bold">
                        En savoir plus
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-1@s uk-margin uk-box-shadow-small uk-box-shadow-hover-large"
                data-uk-grid
              >
                <div>
                  <div class="uk-card-body">
                    <h3 class="uk-card-title is-primary">SMART CHECK</h3>
                    <p>
                      Plate-forme Smart Check Plate-forme évolutive de
                      reconnaissance faciale capable de traiter plusieurs flux
                      vidéo en temps réel Nous construisons un monde où
                      les personnes se font confiance ! Nous offrons des
                      solutions d’identifications biométriques faciales aux
                      gouvernements et les entreprises.
                    </p>
                    <p>
                      <br />
                      <button class="uk-button uk-button-danger uk-text-bold">
                        En savoir plus
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-1@s uk-margin uk-box-shadow-small uk-box-shadow-hover-large"
                data-uk-grid
              >
                <div>
                  <div class="uk-card-body">
                    <h3 class="uk-card-title is-primary">
                      SOLUTIONS DE COMPTAGE DE PERSONNES POUR L'ANALYSE DU
                      TRAFIC 
                    </h3>
                    <p>
                      La gestion et l'optimisation de l'environnement des
                      magasins de détail requièrent des compteurs de personnes
                      capables de délivrer des informations précises et fiables.
                      Nos caméras sont la clé pour capturer l’information de
                      trafic en magasin et centre commerciaux etc.
                    </p>
                    <p>
                      <br />
                      <button class="uk-button uk-button-danger uk-text-bold">
                        En savoir plus
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-1@s uk-margin uk-box-shadow-small uk-box-shadow-hover-large"
                data-uk-grid
              >
                <div>
                  <div class="uk-card-body">
                    <h3 class="uk-card-title is-primary">
                      GARDEZ UN ŒIL SUR VOS EMPLOYÉS GRÂCE AU MODULE DE GESTION
                      DE PRÉSENCE.
                    </h3>
                    <p>
                      À l’aide des informations obtenues en temps réel sur les
                      absences de vos collaborateurs, profitez d'une
                      planification plus précise. En intégrant des dispositifs
                      biométriques (notre technique de reconnaissance faciale),
                      vous obtiendrez des rapports qui amélioreront votre prise
                      de décision.
                    </p>
                    <p>
                      <br />
                      <button class="uk-button uk-button-danger uk-text-bold">
                        En savoir plus
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-1@s uk-margin uk-box-shadow-small uk-box-shadow-hover-large"
                data-uk-grid
              >
                <div>
                  <div class="uk-card-body">
                    <h3 class="uk-card-title is-primary">ASTOR PARKING</h3>
                    <p>
                      ASTOR PARKING est une solution qui trace et contrôle
                      l’accès des véhicules par la reconnaissance automatique de
                      leurs plaques d’immatriculation.
                    </p>
                    <p>
                      <br />
                      <button class="uk-button uk-button-danger uk-text-bold">
                        En savoir plus
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-1@s uk-margin uk-box-shadow-small uk-box-shadow-hover-large"
                data-uk-grid
              >
                <div>
                  <div class="uk-card-body">
                    <h3 class="uk-card-title is-primary">
                      SÉCURITÉ MULTISITE CAMPUS SCOLAIRES
                    </h3>
                    <p>
                      Dans un environnement de type campus avec plusieurs
                      bâtiments, les multiples options d'activation de CONTROLL
                      SCHOOL vous permettront de lutter contre l’absentéisme en
                      intégrant l'intelligence artificielle pour la gestion
                      d'accès des élèves, la gestion de présence dans l’enceinte
                      des établissements.
                    </p>
                    <p>
                      <br />
                      <button class="uk-button uk-button-danger uk-text-bold">
                        En savoir plus
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div
                class="uk-card uk-card-default uk-grid-collapse uk-child-width-1-1@s uk-margin uk-box-shadow-small uk-box-shadow-hover-large"
                data-uk-grid
              >
                <div>
                  <div class="uk-card-body">
                    <h3 class="uk-card-title is-primary">
                      STATIONS DE SURVEILLANCE
                    </h3>
                    <p>
                      Une gestion en temps réel des alertes par le centre de
                      télésurveillance. ASTOR PROTECT est doté de sa propre
                      plateforme de télésurveillance pour le traitement des
                      alertes anti-intrusions. Le centre de télésurveillance
                      basé au Cameroun répond à toutes les exigences de cette
                      norme qui garantit la qualité des prestations contre
                      l'intrusion.
                    </p>
                    <p>
                      <br />
                      <button class="uk-button uk-button-danger uk-text-bold">
                        En savoir plus
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
}

export { Home };
