import React, { Fragment, useEffect } from "react";

import UIKit from "./utils/uikit";
import Router from "./routes";

function App() {
  return (
    <UIKit>
      <Router />
    </UIKit>
  );
}

export default App;
