import React, { Fragment } from "react";
import { Link } from "react-router-dom";

function Header() {
  return (
    <Fragment>
      <header
        data-uk-sticky="sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky"
        className="uk-box-shadow-small bg-header-color"
      >
        <section className="uk-margin-remove uk-padding-remove background-is-black uk-visible@m">
          <div className="uk-container">
            <div className="uk-grid">
              <div className="uk-width-1-2">
                <div className="as-contact">
                  <span className="">
                    <a className="color-is-white"> Connexion </a>{" "}
                  </span>
                  <span className="uk-text-capitalize">
                    <a className="color-is-white">| S'enregistrer </a>
                  </span>
                </div>
              </div>
              <div className="uk-width-1-2 uk-flex uk-flex-right">
                <div className="uk-inline">
                  <a className="uk-button" type="">
                    <img
                      src={require("../assets/img/fr.png").default}
                      style={{ height: "20px", width: "20px" }}
                    />
                    Francais
                  </a>
                  <div
                    uk-dropdown="mode: click; pos: bottom-left"
                    className="uk-padding-remove as-dropdown"
                  >
                    <img
                      src={require("../assets/img/english.jpg").default}
                      alt=""
                      height="20px"
                      width="20px"
                    />
                    Anglais
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="uk-container-expand">
          <nav className="uk-navbar-container" data-uk-navbar>
            <div className="uk-navbar-left uk-visible@m">
              <ul className="uk-navbar-nav">
                <li>
                  <Link className="uk-navbar-item uk-logo" title="Logo" to="/">
                    <img
                      src={require("../assets/img/logo.png").default}
                      style={{ width: "150px" }}
                    />
                  </Link>
                </li>

                <li className="uk-active">
                  <Link className="" to="/">
                    Accueille
                  </Link>
                </li>

                <li>
                  <a className="is-primary" href="#">
                    Solution Sécurity
                  </a>

                  <div
                    className="uk-navbar-dropdown"
                    uk-dropdown="animation: uk-animation-slide-top-small; duration: 1000;offset: -10"
                  >
                    <ul className="uk-nav uk-navbar-dropdown-nav uk-padding-remove">
                      <li className="uk-padding-remove">
                        <a href="#">Vidéos proction</a>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <a href="#">Pénitencier</a>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <a href="#">Education</a>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <Link to="/alarmes">Alarmes</Link>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <a href="#">Trackng GPS</a>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <a href="#">Télé-vidéosurveillance</a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li>
                  <a className="is-primary" href="#">
                    Service
                  </a>

                  <div
                    className="uk-navbar-dropdown"
                    data-uk-dropdown="animation: uk-animation-slide-top-small; duration: 1000;offset: -10"
                  >
                    <ul className="uk-nav uk-navbar-dropdown-nav uk-padding-remove">
                      <li className="uk-padding-remove">
                        <a href="#">Gestion de présence</a>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <a href="#">Comptage des personnes</a>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <a href="#">Trafic</a>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <a href="#">Gestion de parking</a>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <a href="#">Analyse prédictive</a>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <a href="#">Smart check</a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li>
                  <a className="is-primary" href="#">
                    Secteurs d’activités
                  </a>
                  <div
                    className="uk-navbar-dropdown"
                    data-uk-dropdown="animation: uk-animation-slide-top-small; duration: 1000;offset: -10"
                  >
                    <ul className="uk-nav uk-navbar-dropdown-nav uk-padding-remove">
                      <li className="uk-padding-remove">
                        <a href="#">Supermarché</a>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <a href="#">Stations-services</a>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <a href="#">Événements et expositions</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a className="is-primary" href="#">
                    Evénements et webinaires
                  </a>
                </li>

                <li>
                  <Link className="is-primary" to="/about">
                    Partenaires
                  </Link>

                  <div
                    className="uk-navbar-dropdown"
                    data-uk-dropdown="animation: uk-animation-slide-top-small; duration: 1000;offset: -10"
                  >
                    <ul className="uk-nav uk-navbar-dropdown-nav uk-padding-remove">
                      <li className="uk-padding-remove">
                        <a href="#">Nos partenaires technologies</a>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <a href="#">Devenir partenaires installations</a>
                      </li>
                      <li className="uk-nav-divider"></li>
                      <li>
                        <a href="#">Devenir partenaire revendeurs</a>
                      </li>
                    </ul>
                  </div>
                </li>

                <li>
                  <Link className="is-primary" to="/about">
                    À propos de nous
                  </Link>
                </li>
              </ul>
            </div>

            <div className="uk-navbar-right uk-visible@m">
              <ul className="uk-navbar-nav">
                <li className="uk-margin-small-right">
                  <button className="uk-button uk-button-danger uk-text-bold">
                    Contactez-nous
                  </button>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div className="uk-container uk-container-expand uk-hidden@m">
          <nav className="uk-navbar" id="navbar" data-uk-navbar>
            <div className="uk-navbar-left">
              <a className="uk-navbar-item uk-logo" title="Logo" href="#">
                <img
                  src={require("../assets/img/logo.png").default}
                  style={{ width: "120px" }}
                />
              </a>
            </div>

            <div className="nav-overlay uk-navbar uk-navbar-right uk-hidden@m">
              <a
                href=""
                className="is-primary"
                data-uk-icon="icon: menu; ratio: 2"
                type="button"
                data-uk-toggle="target: #offcanvas-nav"
              ></a>

              <div id="offcanvas-nav" data-uk-offcanvas="overlay: true">
                <div className="uk-offcanvas-bar">
                  <ul className="uk-nav uk-nav-default">
                    <li>
                      <a href="#" className="uk-text-bold color-is-white">
                        Accueillle
                      </a>
                    </li>
                    <li className="uk-nav-divider"></li>

                    <li>
                      <a href="#" className="uk-text-bold color-is-white">
                        Solution Sécurity
                      </a>
                    </li>

                    <li className="uk-nav-divider"></li>

                    <li>
                      <a href="#" className="uk-text-bold color-is-white">
                        Nous Active
                      </a>
                    </li>

                    <li className="uk-nav-divider"></li>

                    <li>
                      <a href="#" className="uk-text-bold color-is-white">
                        Nous Telants
                      </a>
                    </li>

                    <li className="uk-nav-divider"></li>
                    <li>
                      <a href="#" className="uk-text-bold color-is-white">
                        {" "}
                        Presse{" "}
                      </a>
                    </li>

                    <li className="uk-nav-divider"></li>

                    <li>
                      <a href="#" className="uk-text-bold color-is-white">
                        Contactez-nous
                      </a>
                    </li>

                    <li className="uk-nav-divider"></li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </Fragment>
  );
}

export default Header;
