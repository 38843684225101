import React, { Fragment } from "react";

function Footer() {
  return (
    <Fragment>
      <section class="as-footer-section">
        <footer class="as-footer">
          <div class="uk-container">
            <div class="uk-child-width-1-3@m" data-uk-grid>
              <div>
                <a
                  class="uk-navbar-item uk-logo uk-flex uk-flex-left uk-padding-remove uk-margin-remove"
                  title="Logo"
                  href="#"
                >
                  <img
                    src={require("../assets/img/logo.png").default}
                    style={{ width: "180px" }}
                    alt=""
                    uk-img
                  />
                </a>
                <p class="color-is-gray-footer">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam
                </p>

                <a
                  href=""
                  class="uk-icon-button uk-margin-small-right"
                  data-uk-icon="twitter"
                ></a>
                <a
                  href=""
                  class="uk-icon-button uk-margin-small-right"
                  uk-icon="facebook"
                ></a>
                <a href="" class="uk-icon-button" uk-icon="google-plus"></a>
                <br />
                <br />
                <div>
                  <span class="color-is-gray-footer" uk-icon="receiver"></span>

                  <a class="color-is-gray-footer uk-text-capitalize">
                    (+237) 243 100 010 / 691 500 075
                  </a>
                </div>
                <br />
                <div>
                  <span class="color-is-gray-footer" uk-icon="location"></span>

                  <a class="color-is-gray-footer uk-text-capitalize">
                    BP: 5613 Douala Akwa-Cameroun
                  </a>
                </div>
                <br />
                <div>
                  <span class="color-is-gray-footer" uk-icon="mail"></span>

                  <a class="color-is-gray-footer uk-text-capitalize">
                    sales@astorprotect.com
                  </a>
                </div>
              </div>
              <div>
                <h4 class="color-is-white uk-h3">Services</h4>
              </div>
              <div>
                <h4 class="color-is-white uk-h3">Liens rapides</h4>
              </div>
            </div>
          </div>
        </footer>
      </section>

      <div style={{ height: "600px", overflow: "hidden" }}>
        <svg
          viewBox="0 0 500 150"
          preserveAspectRatio="none"
          style={{ height: "100%", width: "100%" }}
        >
          <path
            d="M-0.85,33.83 C71.38,-38.20 375.56,39.77 500.84,58.52 L500.00,150.00 L0.00,150.00 Z"
            style={{ stroke: "none", fill: "#101010" }}
          ></path>
        </svg>
      </div>
    </Fragment>
  );
}

export default Footer;
